@import './mixins';
@import './variables';

:root {
  @include defineBaseColorVariables($colors);
  @include defineTintColorVariables($colors);
  @include defineShadeColorVariables($colors);
  @include defineZIndexVariables();
}

@include defineCustomFontFamily('roboto', 800);

:root {
  --body-background-color: #fff;
  --max-element-width: 2000px;
  --max-content-width: 1480px;
  --content-margin: 25px;
  --base-font-size: 16px;
  --base-border-radius: 8px;
  --background-base-color: white;
  --navbar-height: 50px;
}

body {
  font-family: var(--font-family);
  background-color: var(--body-background-color);
  color: var(--text-color);
  font-size: var(--base-font-size);
}
html {
  scroll-padding-top: 145px;
}

a {
  color: var(--link-color);

  &:hover {
    text-decoration: underline;
  }
}

a {
  &.normal-color {
    color: var(--text-color);
  }

  &.special {
    color: var(--primary-color);
  }
}

h1,
h2,
h3,
h4 {
  font-weight: var(--bold-font-weight);
  margin: 0;
}

h1 {
  font-size: 30px;
  line-height: 34px;

  + .g_body {
    margin-top: 12px;
  }
}

h3,
.custom-h3 {
  font-size: 30px;
  line-height: 34px;

  + .g_body {
    margin-top: 6px;
  }
}

h4 {
  font-size: 18px;
  line-height: 22px;
}

.g_body {
  font-size: 16px;
  line-height: 22px;
}

.g_body_bold,
.g_subheader_bold {
  font-weight: var(--bold-font-weight);
}

.g_subheader {
  font-size: 24px;
  line-height: 32px;
}

@media #{$size-md} {
  // h1 {
  //   font-size: 68px;
  //   line-height: 72px;

  //   + .g_subheader {
  //     margin-top: 20px;
  //   }
  // }
  h1 {
    font-size: 36px;
    line-height: 38px;

    + .g_body {
      margin-top: 8px;
    }
  }

  .g_body {
    font-size: 18px;
    line-height: 24px;
  }

  h3,
  .custom-h3 {
    font-size: 36px;
    line-height: 42px;

    + .g_body {
      margin-top: 12px;
    }
  }

  h4 {
    font-size: 24px;
    line-height: 28px;
  }

  .g_subheader {
    font-size: 36px;
    line-height: 48px;
  }
}

@media #{$size-lg} {
  h1 {
    font-size: 56px;
    line-height: 60px;

    + .g_body {
      margin-top: 8px;
    }
  }

  .g_body {
    font-size: 24px;
    line-height: 32px;
  }

  h3,
  .custom-h3 {
    font-size: 36px;
    line-height: 42px;

    + .g_body {
      margin-top: 12px;
    }
  }

  h4 {
    font-size: 24px;
    line-height: 28px;
  }

  .g_subheader {
    font-size: 36px;
    line-height: 48px;
  }
}

:root {
  --spacing-lg: 100px;
  --spacing-md: 60px;
  --spacing-sm: 45px;
  --spacing-xs: 30px;
  --spacing-xss: 20px;

  @media #{$size-md} {
    --spacing-lg: 120px;
    --spacing-md: 75px;
    --spacing-sm: 55px;
    --spacing-xs: 35px;
    --spacing-xss: 25px;
  }

  @media #{$size-lg} {
    --spacing-lg: 160px;
    --spacing-md: 90px;
    --spacing-sm: 70px;
    --spacing-xs: 50px;

    --spacing-xss: 36px;
  }
}

.padding-top-lg {
  padding-top: var(--spacing-lg);
}

.padding-top-md {
  padding-top: var(--spacing-md);
}

.padding-top-sm {
  padding-top: var(--spacing-sm);
}

.padding-top-xs {
  padding-top: var(--spacing-xs);
}

.padding-bottom-lg {
  padding-bottom: var(--spacing-lg);
}

.padding-bottom-md {
  padding-bottom: var(--spacing-md);
}

.padding-bottom-sm {
  padding-bottom: var(--spacing-sm);
}

.padding-bottom-xs {
  padding-top: var(--spacing-xs);
}

.no-scroll {
  overflow: hidden;
}

@media #{$size-md} {
  .no-scroll {
    overflow: auto;
  }
}

.html-content {
  ul,
  ol {
    padding-left: 60px;
  }
}

.container-section {
  padding-top: var(--spacing-md);
  padding-bottom: var(--spacing-md);

  .container-section-header {
    text-align: center;
    font-size: 24px;
    line-height: 26px;
    padding-bottom: 30px;

    margin: 0;
    font-weight: var(--bold-font-weight);

    @media #{$size-md} {
      font-size: 28px;
      line-height: 30px;
      padding-bottom: 35px;
    }

    @media #{$size-lg} {
      font-size: 32px;
      line-height: 38px;
      padding-bottom: calc(66px - 38px);
    }

  }

  .container-section-body {
    display: block;

    + .container-section-show-all-link {
      padding-top: var(--spacing-sm);
    }
  }

  .container-section-show-all-link {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;

    @media #{$size-md} {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.hr-divider {
  border-bottom: 1px solid var(--text-tint-95);
}

.container-section-show-all-link {
  // text-align: center;
  // margin-top: var(--spacing-xss);

  color: var(--link-color);
  height: var(--spacing-md);
  // background-color: red;
  // padding-top: var(--spacing-xss);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;

  @media #{$size-lg} {
    // padding-bottom: calc(var(--spacing-xss) - 6px);
  }

  @media #{$size-sm-and-below} {
    line-height: 20px;
    font-size: 14px;
  }

  a {
    padding: 0 8px;
  }

  &:hover a {
    color: var(--link-shade-5);
  }
}

.tab-header {
  padding-bottom: calc(var(--spacing-xs) / 2);
  color: var(--text-color);

  text-align: center;

  @media #{$size-md} {
    padding-bottom: var(--spacing-xs);
  }
}

.app-hero-header-new,
.product-service-banner {
  border-radius: 0 0 20px 20px;

  // background-image: linear-gradient(
  //   180deg,
  //   rgba(243, 246, 248, 0.34) 0%,
  //   var(--text-tint-80) 100%
  // );
  @media #{$size-md} {
    border-radius: 0 0 29px 29px;
  }

  @media #{$size-lg} {
    border-radius: 0 0 35px 35px;
  }
}

.product-service-banner {
  position: relative;
  background-image: linear-gradient(
    to bottom,

    var(--primary-tint-66),
    var(--primary-tint-95)
  );
  // padding-inline: var(--content-margin);
  // padding-inline: var(--content-margin);

  // margin: var(--content-margin);
  // padding-bottom: calc(106px - 30px - 15px);
  padding-bottom: calc(106px - var(--spacing-xs));

  @media #{$size-md} {
    padding-bottom: calc(164px - var(--spacing-xs));
  }

  @media #{$size-lg} {
    padding-bottom: calc(292px - var(--spacing-xs));
  }
}

.app-hero-header-new {
  background-image: linear-gradient(
    180deg,
    var(--primary-tint-90) 0%,
    var(--primary-tint-95) 100%
  );
  padding-bottom: var(--spacing-xs);
  margin-bottom: var(--spacing-xs);
}

.container-module {
  @media #{$size-sm-and-below} {
    --content-margin: 0;
  }
}

.description-detail {
  --max-content-width: 100%;

  padding-inline: 0;
  margin-inline: auto;

  @media #{$size-md} {
    --max-content-width: 66.66%;
    padding-inline: var(--content-margin);
  }

  @media #{$size-lg} {
    --max-content-width: 50%;
  }
}

.description-spacing {
  padding-top: var(--spacing-sm);
  padding-bottom: calc(var(--spacing-md) * 2);
  // padding-inline: 11px;
  // @media #{$size-md} {
  //   padding-inline: 15px;
  // }
}

.article-spacing {
  margin-inline: auto;
  padding-top: var(--spacing-xs);
  padding-bottom: calc(var(--spacing-md) * 2);

  @media #{$size-md} {
    max-width: calc(8 / 12 * 100%); // 10 column on tablet
  }

  @media #{$size-lg} {
    // max-width: 855px;

    // max-width: 50%;
    max-width: calc(7 / 12 * 100%);
    // max-width: calc(1480 / 6 * 12);
  }
}

app-awards {
  --section-header-font-size: 24px;
  --section-header-line-height: 26px;
  --section-subheader-font-size: 14px;
  --section-subheader-line-height: 20px;
  --item-header-font-size: 14px;
  --item-header-line-height: 20px;
  --item-subheader-font-size: 14px;
  --item-subheader-line-height: 20px;

  @media #{$size-md} {
    --section-header-font-size: 28px;
    --section-header-line-height: 30px;
    --section-subheader-font-size: 16px;
    --section-subheader-line-height: 24px;
    --item-header-font-size: 16px;
    --item-header-line-height: 24px;
    --item-subheader-font-size: 16px;
    --item-subheader-line-height: 24px;
  }

  @media #{$size-lg} {
    --section-header-font-size: 32px;
    --section-header-line-height: 36px;
  }
}
